<template>
  <div class="valorador vs-con-loading__container" v-if="ready">
    <div v-if="ready">
      <img
        :src="client.logo"
        :width="logoWidth()"
        :height="logoHeight()"
        :style="'marginBottom: ' + client.logoMargin + 'px'"
      />
      <br />
      <!-- Destino: {{target}} ({{luck}})<br><br> -->
      <h1 :style="'color:' + client.textColor" v-if="!customer.name">{{ client.title }}</h1>
      <h1
        :style="'color:' + client.textColor"
        v-if="customer.name"
      >{{ client.namedTitle.replace('%n', customer.name) }}</h1>
      <h3 :style="'color:' + client.textColor">{{ client.description }}</h3>
      <br />
      <br />
      <star-rating v-model="rating" :inline="true" text-class="hide" />
      <br />
      <div>
        <br />
        <h3
          :style="'color:' + client.textColor"
          v-if="showFeedback || showSent"
        >{{ client.lowRatingMessage }}</h3>
        <br />
        <center>
          <vs-input
            type="text"
            v-model="name"
            label-placeholder="Nombre"
            style="width:500px"
            v-if="showFeedback && !customer.name"
          />
          <br v-if="!customer.name" />
          <vs-input
            type="text"
            v-model="email"
            label-placeholder="Email"
            style="width:500px"
            v-if="showFeedback && !customer.name"
          />
          <br v-if="!customer.name" />
          <vs-textarea
            v-model="feedback"
            style="width:500px"
            rows="10"
            counter="600"
            v-if="showFeedback"
          />
          <h1 :style="'color:' + client.textColor" v-if="showSent">
            <br />
            {{ client.thanks }}
          </h1>
        </center>
        <br />
        <vs-button
          @click="btnLoader($event, sendFeedback)"
          :disabled="feedback.length < 3"
          v-if="showFeedback"
        >Enviar</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import StarRating from 'vue-star-rating'
import btnLoader from './btnLoader'
import { db, query, getDoc, doc, onSnapshot, collection, addDoc, where, httpsCallable, functions } from '../firebase'

const ratioChangingX = (X, origX, origY) => X * (origY / origX);
const ratioChangingY = (Y, origX, origY) => Y * (origX / origY);

export default {
  name: 'Valorador',
  components: { StarRating },
  data() {
    return {
      rating: 0,
      client: {},
      showFeedback: false,
      feedback: '',
      css: '',
      target: '',
      ready: false,
      luck: 0,
      name: '',
      showFeedback: false,
      showSent: false,
      email: '',
      phone: '',
      customer: {},
    }
  },
  beforeDestroy() {
    document.body.style.cssText -= this.css;
    this.unsub();
  },
  async mounted() {
    this.btnLoader = btnLoader(this);
    let requestedRating = location.search.match(/rate=(\d)/)
    if (requestedRating && requestedRating[1]) requestedRating = requestedRating[1]

    const q = query(collection(db, "clients"), where("uri", "==", this.$route.params.uri));
    this.unsub = onSnapshot(q, async snapshot => {
      if (!snapshot.docs[0]) {
        this.client = null;
        return;
      }
      //obtener empresa (y cliente final si se especifica en la ruta)
      this.client = { id: snapshot.docs[0].id, ...snapshot.docs[0].data() }
      if (this.$route.params.customer) {
        this.customer = (await getDoc(doc(collection(db, "clients", this.client.id, "customers"), this.$route.params.customer))).data();
        if (!this.customer) {
          this.customer = {};
        }
        this.name = this.customer.name + ' ' + this.customer.lastName;
        this.email = this.customer.email;
        this.phone = this.customer.phone;
      }

      //impedir uso de empresas inactivos
      if (!this.client.active) {
        this.client = null;
        return this.$router.push('/');
      }

      this.target = this.getHighRateLink();
      this.css = `background: ${this.client.backgroundColor}!important; color: ${this.client.textColor}!important`;
      document.body.style.cssText += this.css;

      if (requestedRating) {
        if (+requestedRating > 3) {
          this.processHighRating(+requestedRating)
        }
        else {
          console.log(`sale`,);
          this.rating = +requestedRating;
          this.ready = true;
        }
      }
      else {
        this.ready = true;
      }

    });


  },
  methods: {
    async processHighRating(number, anonymous = false) {
      const target = this.getHighRateLink();
      await addDoc(collection(db, 'clients', this.client.id, 'feedbacks'), {
        ...{ ...anonymous ? { name: 'Visitante anónimo' } : { name: this.name, email: this.email } },
        rating: number,
        created: new Date(),
        content: `Valoración alta en ${target}`,
      });
      location.replace(target)
    },
    getHighRateLink() {
      // const luck = Math.floor(Math.random()*100)+1;
      // const target = !this.client.facebookUrl || this.luck <= this.client.googlePreference ? 
      //   this.client.googleUrl : 
      //   this.client.facebookUrl;
      return this.client.googleUrl;
    },
    logoWidth() {
      return +this.client.logoWidth > 1 ? this.client.logoWidth : this.client.logoNaturalWidth;
    },
    logoHeight() {
      if (+this.client.logoWidth > 1) {
        return Math.floor(ratioChangingX(+this.client.logoWidth, this.client.logoNaturalWidth, this.client.logoNaturalHeight));
      }
      else {
        return this.client.logoNaturalHeight;
      }
    },
    sendFeedback(stopLoader) {
      if (this.feedback.length > 599) return alert('Máximo 600 caracteres');
      addDoc(collection(db, 'clients', this.client.id, 'feedbacks'), {
        name: this.name,
        email: this.email,
        rating: this.rating,
        created: new Date(),
        content: this.feedback,
      }).then(() => {
        this.showFeedback = false;
        this.showSent = true;
        stopLoader(true);

        //enviar correo con feedback
        const templateData = {
          userName: this.name,
          userEmail: this.email,
          ratingCount: this.rating + ' estrella' + (this.rating == 1 ? '' : 's'),
          rating: '⭐️'.repeat(this.rating),
          feedback: this.feedback,
          logo: this.client.logo,
          companyFeedbackUrl: location.protocol + '//' + location.host + '/opiniones',
        };
        httpsCallable(functions, 'mail')({ action: 'sendEmail', to: this.client.feedbackEmail, templateId: 'd-2e40e78956dc421cbfa5527577ae0932', templateData })

      }).catch(err => {
        stopLoader(false);
        alert('Error al enviar: ' + err);
      });
      // this.showFeedback = false
      // this.$http.post('/api/feedback', {
      //   rating: this.rating,
      //   feedback: this.feedback
      // })
    }
  },
  watch: {
    rating: function (val) {
      if (val < 4) {
        this.showFeedback = true
      } else {
        if (this.ready) this.processHighRating(val, true)
      }
      this.$emit('rating', val)
    }
  }
}
</script>

<style>
.valorador .vs-placeholder-label {
  text-align: left !important;
}

.valorador .vs-con-textarea {
  background: white;
}

.valorador {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  text-align: center;
  /* height: 100vh; */
}
</style>